export const SEARCHZONE = "SEARCHZONE";

const getZoneSearch = (searchValue) => {
    return async function (dispatch) {
        dispatch(setZone(searchValue));
    };
};

export const setZone = (search = {}) => {
    return{
        type: SEARCHZONE,
        payload: search,
    };
};

export default getZoneSearch;