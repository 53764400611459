import React, {useState} from "react";
import searchIcong from "../../assets/img/icon-search_default.png";
import searchIconb from "../../assets/img/icon-search_active.png";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import getZoneSearch from "../../store/searchzone/action";

const Search = ({getZoneSearch, zones, providers, cities}) => {
    const {register, handleSubmit} = useForm();
    const [error, setError] = useState('')

    const onSubmit = (data) => {
        let finalvalues = [];
        let result = [];
        const filteredresults = zones[0].filter(z => z.Zone === `${data.zone}`);
        if (filteredresults.length > 0) {
            filteredresults.map((value) =>
                finalvalues.push(
                    {
                        city: value.City,
                        provider: value.Provider
                    }
                )
            )
        }
        if (finalvalues.length > 0) {
            result = finalvalues.reduce((acc, {city, provider}) =>
                ({...acc, [city]: [...(acc[city] || []), provider]}), {});
        }
        if (Object.keys(result).length === 1) {
            setError('')
            let providerCity = [];
            // eslint-disable-next-line array-callback-return
            Object.keys(result).map(p => {
                    providerCity.push(cities.filter(city => city.id === p));
                }
            )
            getZoneSearch({
                searchValue: data.zone,
                searchB: data.zone.length > 0,
                searchD: true,
                providersList: providers,
                citiesList: [],
                providerCity: providerCity,
            })

        } else if (Object.keys(result).length > 1) {
            setError('')
            let citiesArray = [];
            Object.keys(result).map(p =>
                // eslint-disable-next-line array-callback-return
                cities.map((city) => {
                    if (city.id === p) {
                        citiesArray.push(city)
                    }
                })
            )
            getZoneSearch({
                searchValue: data.zone,
                searchB: data.zone.length > 0,
                searchD: true,
                providersList: [],
                citiesList: citiesArray,
                citiesWithProvider: result,
                parkAnkPay: []
            })

        } else {
            setError('Invalid Zone Number')
        }
    }

    const findValue = (e) => {
        if (e.which === 13 && e.target.value.length > 0) {
            handleSubmit(onSubmit)();
            e.target.blur();
        }
    }

    return (
        <div className="search-zone">
            <div className="container">
                <form onSubmit={handleSubmit(onSubmit)} className="search-field">
                    <input type="text" autoComplete="off" onKeyUp={(e) => findValue(e)} placeholder="Enter Zone Number"
                           {...register("zone")}
                           className={error || error.length > 0 ? "search-error" : ''}
                           inputMode='numeric'
                    />
                    <button type="submit">
                        <img src={searchIcong} alt={"search"}/>
                        <img src={searchIconb} alt={"search"}/>
                    </button>
                    <span className="text-red"><b>{error.length > 0 ? error : ''}</b></span>

                </form>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        zones: state.zones,
        providers: state.appData.providers,
        cities: state.appData.cities
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getZoneSearch: (value) => {
            dispatch(getZoneSearch(value));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search)