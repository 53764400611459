import React, {useEffect, useState} from "react";
import Home from "../components/home/Home"
import {connect} from "react-redux";
import Welcome from "../components/Welcome";

const Index = ({location}) => {

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (location && location.length > 0) {
            setLoading(false)
        }
        //eslint-disable-next-line
    }, [loading, location])

    if (loading) {
        return <Welcome/>;
    }
    return <Home/>

}

const mapStateToProps = (state) => {
    return {
        location: state.location,
    };
};
export default connect(mapStateToProps)(Index);