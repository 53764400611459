import React from "react";
import {Link} from "react-router-dom";
import parkandpay from "../../assets/img/logo-parkandpay-01.svg"

const Banner1 = ({city}) => {
    return (
        <>
            <section className="banner" style={city? {backgroundImage: `url('/cities/headers/${city.header}')`}:{backgroundImage: `url('/cities/headers/Generic_header.jpg')`}}>
                <div className="container">
                    <div className="logo-section dual-logo">
                        <Link to="/"><img src={parkandpay} alt="logo"/></Link>
                        <Link to="/"><img src={`/cities/logos/`+city.icon} alt={city.name}/></Link>
                    </div>
                </div>
                <div className="banner-text">
                    <h2>Park and pay <span>your way<strong>.</strong></span></h2>
                    <p>We make if fast and easy to pay for parking. Pick from a variety of contactless payment options
                        and start you parking session.</p>
                </div>
            </section>
        </>
    )
}
export default Banner1