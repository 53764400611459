import data from "../../components/data/appdefinition.json";
export const APPDATA = "APPDATA";

const getAppData = () => {
    return async function (dispatch) {
        dispatch(setAppData(data));
    };
};

export const setAppData = (appdata = []) => {
    return{
        type: APPDATA,
        payload: appdata,
    };
};
export default getAppData;