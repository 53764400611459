import csv from "../../components/data/zonedata.csv";
import {readRemoteFile} from "react-papaparse";

export const ZONE = "ZONE";
const getZoneData = () => {
    return async function (dispatch) {
        readRemoteFile(
            csv,
            {
                header: true,
                complete: (results) => {
                    dispatch(setZone([].concat(...results.data)));
                }
            }
        )
    };
};

export const setZone = (zones = []) => {
    return{
        type: ZONE,
        payload: zones,
    };
};

export default getZoneData;