import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import Content from "./content";
import SearchLoader from "./SearchLoader";
import Cities from "../Cities";
import ProviderPay from "./providerpay";


const HomeContent = ({search}) => {
    const [showloader, setShowLoader] = useState(false);
    useEffect(() => {

        if (search.searchB && (search.citiesList.length <= 0 || search.providersList.length <= 0)) {
            setShowLoader(true)
        }
        if (search.searchB && search.searchD){
            setTimeout(() => {
                setShowLoader(false)
            }, 1000);
        }
        // eslint-disable-next-line
    }, [search.citiesList, search.providersList])

    return (
        <>
            {showloader && search.searchB &&
            <SearchLoader search={search.searchB}/>
            }

            {!search.searchB && search.searchValue.length >= 0 &&
            <Content/>
            }

            {search.searchB && search.searchD && search.citiesList.length > 0 && !showloader &&
            <Cities search={search}/>
            }

            {search.searchB && search.searchD && search.providersList.length > 0 && !showloader &&
            <ProviderPay search={search}/>
            }

        </>

    )

}
const mapStateToProps = (state) => {
    return {
        search: state.search,
        providers: state.appData.providers,
        cities: state.appData.cities,
    };
};

export default connect(mapStateToProps)(HomeContent)