import React from "react";
import getZoneSearch from "../store/searchzone/action";
import {connect} from "react-redux";


const City = ({search,providers,getZoneSearch,cities}) => {
    const providerData = (data, id, searchedValue) => {
        let providerCity=[];

        // eslint-disable-next-line array-callback-return
        Object.keys(data).map(p => {
                if (id === p) {
                    providerCity.push(cities.filter(city => city.id === p));
                }
            }
        )
        getZoneSearch({
            searchValue: searchedValue,
            searchB: true,
            searchD: true,
            providersList: providers,
            citiesList: data,
            providerCity:providerCity,
        })
    }
    return (
        <>
            <section className="select-location">
                <div className="container">
                    <div className="head">
                        <h1> Select a Location: </h1>
                    </div>
                    <div className="location-content">
                        <ul>
                            {search && search.providersList && search.citiesList.length > 0 &&
                            search.citiesList.map((city) =>
                                <li key={city.id}>
                                    <span className="text-dp">{city.state}</span>
                                    <div className="r-name">
                                        <h2>{city.name}, {city.state}</h2>
                                    </div>
                                    <button
                                        onClick={() => providerData(search.citiesWithProvider, city.id, search.searchValue)}>Select
                                    </button>
                                </li>
                            )
                            }
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        search: state.search,
        providers: state.appData.providers,
        cities: state.appData.cities,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getZoneSearch: (value) => {
            dispatch(getZoneSearch(value));
        }
    };
};
export default connect( mapStateToProps,mapDispatchToProps)(City);