import { combineReducers } from 'redux';

import SnoopiReducer from '../store/snoopi/reducer'
import ZoneReducer from "./zone/reducer";
import AppDataReducer from "./appData/reducer";
import ZoneSearchReducer from "./searchzone/reducer";

export default combineReducers({
    location: SnoopiReducer,
    zones: ZoneReducer,
    appData:AppDataReducer,
    search:ZoneSearchReducer
});
