import React, { useRef } from "react";
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

const Content=()=>{

    const item1Ref = useRef();
    const item2Ref = useRef();
    const item3Ref = useRef();
    const item4Ref = useRef();

    useScrollPosition(({ currPos }) => {
        // console.log(currPos.y);
        // console.log('item1', item1Ref.current.offsetTop);
        // console.log('item2', item2Ref.current.offsetTop);
        // console.log('item3', item3Ref.current.offsetTop);
        // console.log('item4', item4Ref.current.offsetTop);

        if ((currPos.y * -1) + 400 > item1Ref.current.offsetTop) {
            item1Ref.current.classList.add('animate-it-right');
        }
        if ((currPos.y * -1) + 400 > item2Ref.current.offsetTop) {
            item2Ref.current.classList.add('animate-it-left');
        }
        if ((currPos.y * -1) + 400 > item3Ref.current.offsetTop) {
            item3Ref.current.classList.add('animate-it-right');
        }
        if ((currPos.y * -1) + 400 > item4Ref.current.offsetTop) {
            item4Ref.current.classList.add('animate-it-left');
        }
      })
      
    return(
        <div className="body-content">
            <section className="how-it-work">
                <div className="container">
                    <div className="head">
                        <h1>How it works: </h1>
                    </div>
                    <div className="it-works">
                        <ul>
                            <li ref={item1Ref}>
                                <span>1.</span> <p>Enter the Zone Number on the signs or sticker</p>
                            </li>
                            <li ref={item2Ref}>
                                <p>Choose your web or mobile parking app</p> <span>2.</span>
                            </li>
                            <li ref={item3Ref}>
                                <span>3.</span> <p>Make your payment</p>
                            </li>
                            <li ref={item4Ref}>
                                <p>You're good to go!</p> <span>4.</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Content