export const LOCATIONBYIP = "LOCATIONBYIP";
export const LocationByIP = [];

const SnoopiReducer = (state = LocationByIP, action) => {
    switch (action.type) {
        case LOCATIONBYIP:
            return [action.payload];
        default:
            return state;
    }
};

export default SnoopiReducer;