import {Link} from "react-router-dom";
import footerLogo from "../../assets/img/Parking Logos/ParkMobile Powered by White.png";
import React from "react";
import {connect} from "react-redux";

const Footer=({content})=>{
    return(
    <section className="footer-section">
        <div className="container">
            <div className="copy-rights">
                <Link to="/" className="f-logo">
                    <img src={footerLogo} alt={"park and pay"}/>
                </Link>
                <div className="footer-menu">
                    <p>©2021 ParkMobile, LLC. All rights reserved.</p>
                    <ul>
                        <li><a href={content.termUrl}>Terms</a></li>
                        <li className="divider"/>
                        <li><a href={content.privacyUrl}>Privacy</a></li>
                        <li className="divider"/>
                        <li><Link to="/">Do Not Sell My Personal Information</Link></li>
                    </ul>
                </div>
            </div>
        </div>

    </section>
    )
}
const mapStateToProps = (state) => {
    return {
        content: state.appData.content,
    };
};
export default connect(mapStateToProps)(Footer)
