import React from "react";
import Search from "./search";
// import Iframe from "./Iframe";
import FAQ from "./faq";
import Footer from "./footer";
import HomeContent from "./HomeMainContent";
import Header from "../partials/header";

const Home = () => {

    return (
        <>
            <div className="wrapper">
                <Header/>
                <Search />

                <HomeContent/>

                {/* <Iframe /> */}
                <FAQ/>
                <Footer/>
            </div>
        </>
    );

}

export default Home