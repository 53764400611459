export const SEARCHZONE = "SEARCHZONE";
export const initialStateSearchZone = {
    searchValue: '',
    searchB: false,
    searchD: false,
    providersList: [],
    citiesList:[]
};

const ZoneSearchReducer = (state = initialStateSearchZone, action) => {
    switch (action.type) {
        case SEARCHZONE:
            return action.payload;
        default:
            return state;
    }
};

export default ZoneSearchReducer;