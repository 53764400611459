import React from 'react';
import ReactDOM from "react-dom";
import {Provider} from 'react-redux';
import store from "./store/store";
import App from "./App";
import getLocationIp from "./store/snoopi/action";
import getAppData from "./store/appData/action";
import getZoneData from "./store/zone/action";
import getZoneSearch from "./store/searchzone/action";

store.dispatch(getLocationIp());
store.dispatch(getAppData());
store.dispatch(getZoneData());
store.dispatch(getZoneSearch({
    searchValue: '',
    searchB: false,
    searchD: false,
    providersList: [],
    citiesList: [],
    citiesWithProvider: [],
    providerCity: []
}));


ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>
    , document.getElementById('root')
);

