import React from "react";

const SearchLoader=({search})=>{

    return(
        <div className="body-loader" style={search ? {'display': 'block'} : {'display': 'none'}}>
            <div><span>Loading...</span></div>
        </div>
    )
}
export default SearchLoader