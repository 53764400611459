import React, {useEffect, useState} from "react";

const ProviderPay = ({search}) => {

    const [data,setData]=useState([]);

    useEffect(() => {
        let providers = []
        // eslint-disable-next-line array-callback-return
        search && search.providersList.map((provider) => {
            const object = {
                "id": provider.id,
                "name": provider.name,
                "image": provider.image,
                "web": provider.web?.replace('{zone_id}',search.searchValue),
                "app": provider.app?.replace('{zone_id}',search.searchValue)
            }
            providers.push(object)
        })
        setData(providers)
    },[search])

    return (
        <section className="pay-method">
            <div className="container">
                <div className="head">
                    <h1> Ways to pay in <br/>{search.providerCity[0][0].name}, {search.providerCity[0][0].state} at
                        "{search.searchValue}": </h1>
                </div>
                <div className="methods">
                    <ul>
                        {search && search.providersList && search.providersList.length > 0 &&
                        data.map((provider) =>
                            <li key={provider.id}>
                                <img src={`/provider/` + provider.image} alt={provider.id}/>
                                <div className="pay-on">
                                    {provider && provider.web &&
                                    <a href={provider.web} target={'_blank'}
                                       rel="noreferrer">Pay Now</a>}
                                    {provider && provider.app &&
                                    <a href={provider.app} target={'_blank'} rel="noreferrer">Pay Now</a>}
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </section>
    )
}
export default ProviderPay