export const APPDATA = "APPDATA";

export const initialStateZone = {
    'providers': [],
    'cities': [],
    'faqs': [],
    'video': ''
};

const AppDataReducer = (state = initialStateZone, action) => {
    switch (action.type) {
        case APPDATA:
            return action.payload;
        default:
            return state;
    }
};

export default AppDataReducer;