import React from "react";
import logo from "../assets/img/pp-logo.png";
import loader from "../assets/img/loader.gif";
import footer from "../assets/img/power-white.png";

const Welcome = () => {
    return (
        <>
            <div className="wrapper start-screen">
                <section className="start-screen">
                    <div className="loader">
                        <img src={logo} alt={"logo"}/>
                            <img src={loader} alt={"loader"}/>
                    </div>
                    <div className="start-footer">
                        <img src={footer} alt={"footer"}/>
                    </div>
                </section>
            </div>
        </>
        )
};

export default Welcome;