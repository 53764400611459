import React from "react";
import Banner from "./banner";
import Banner1 from "./banner1";
import {connect} from "react-redux";
import {getDistance} from "geolib";
import * as geolib from "geolib";

const Header = ({location, cities, distance_threshold, distance, search}) => {
    // arlington virginia USA latitude: 38.88101,longitude: -77.10428 that is nearest to washington DC

    let citiesArray = [];
    if (cities && cities.length > 0) {
        // eslint-disable-next-line array-callback-return
        cities.map((item) => { //Find distance and update cities list with distance

            let start
            if (distance && distance.overrideLatitude){
                start = {
                    latitude: distance.latitude,
                    longitude: distance.longitude
                }
            }else {
                start = {
                    latitude: location[0].Latitude,
                    longitude: location[0].Longitude
                }
            }

            const end = {
                latitude: item.latitude,
                longitude: item.longitude
            }
            const geo = getDistance(start, end, 1);
            const distanceKM = geolib.convertDistance(geo, 'mi')

            const modifiedCityObj = {
                ...item,
                distanceKM: Math.round(distanceKM)
            };

            citiesArray.push(modifiedCityObj)
        })
    }
    if (citiesArray && citiesArray.length > 0) {  //sorting cities list
        function SortByName(a, b) {
            const distanceA = a.distanceKM;
            const distanceB = b.distanceKM;
            return ((distanceA < distanceB) ? -1 : ((distanceA > distanceB) ? 1 : 0));
        }
        citiesArray.sort(SortByName);
    }

    return (
        <>
            {citiesArray && citiesArray.length > 0 && citiesArray[0].distanceKM <= distance_threshold ?
                <Banner1 city={citiesArray[0]}/>
                :
                search && search.providerCity && search.providerCity.length > 0 ?
                    <Banner1 city={search.providerCity[0][0]}/>
                    :
                    <Banner/>

            }
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        location: state.location,
        cities: state.appData.cities,
        distance_threshold: state.appData.distance_threshold,
        distance: state.appData.distance,
        search: state.search
    };
};

export default connect(mapStateToProps)(Header);
