import * as axios from "axios";
import publicIp from "public-ip";

export const LOCATIONBYIP = "LOCATIONBYIP";
export const LOCATIONNOT = "LOCATIONNOT";

const getLocationIp = () => {
    return async function (dispatch) {

        let ip;
        try {
            ip = await publicIp.v4({
                timeout: 5000
            })//=> '214.52.21.123'
        } catch (e) {
            ip=false;
        }

        if (ip) {
            await axios.get(`https://api.snoopi.io/${ip}?apikey=299146259bf92c5506a87dda178f9dfa`,{
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": 'application/json',
                }
            })
                .then(function (response) {
                    dispatch(setLocationByIP(response.data));
                })
                .catch(errors => {
                    console.log(errors)
                });
        }
    };
};

export const setLocationByIP = (location = []) => {
    return {
        type: LOCATIONBYIP,
        payload: location,
    };
};


export default getLocationIp;