export const ZONE = "ZONE";
export const initialStateZone = [];

const ZoneReducer = (state = initialStateZone, action) => {
    switch (action.type) {
        case ZONE:
            return [action.payload];
        default:
            return state;
    }
};

export default ZoneReducer;