import arrow from "../../assets/img/arrow.svg";
import React, { useState } from "react";
import {connect} from "react-redux";

const FAQ = ({faqs}) => {

    const [selectedIndex, setSelectedIndex] = useState(-1);

    const panelClicked = (index) => {
        if (selectedIndex === index) {
            setSelectedIndex(-1);
        } else {
            setSelectedIndex(index);
        }
    }

    const FAQEntryPanel = ( faqs, index ) => {
        const isExpanded = (selectedIndex === index);
        return (
            <div className={`panel panel-${index + 1}`}
                onClick={() => panelClicked(index)}>
                <h4 className={`${isExpanded ? 'for-img' : ''}`}>{faqs[index].question}<span><img src={arrow} alt={"arrow"}/></span></h4>
                <div className={`panel-content ${isExpanded ? 'panelOpen' : 'panelClosed'}`}>
                    <h5>{faqs[index].answer}</h5>
                </div>
            </div>
        )
    }
    
    return (
        <section className="faq">
            <div className="container">
                <div className="head">
                    <h1> Frequently Asked Questions </h1>
                </div>
                <div className="faq-area">
                    {FAQEntryPanel(faqs, 0)}
                    <div className="divider"/>
                    {FAQEntryPanel(faqs, 1)}
                </div>
                <div className="faq-area">
                    <div className="divider hiddenNonMobile"/>
                    {FAQEntryPanel(faqs, 2)}
                    <div className="divider"/>
                    {FAQEntryPanel(faqs, 3)}
                </div>
                <div className="faq-area">
                    <div className="divider hiddenNonMobile"/>
                    {FAQEntryPanel(faqs, 4)}
                    <div className="divider"/>
                    {FAQEntryPanel(faqs, 5)}
                </div>
                <div className="faq-area">
                    <div className="divider hiddenNonMobile"/>
                    {FAQEntryPanel(faqs, 6)}
                    <div className="divider"/>
                    {FAQEntryPanel(faqs, 7)}
                </div>
            </div>
        </section>
    )
}
const mapStateToProps = (state) => {
    return {
        faqs: state.appData.faq,
    };
};
export default connect(mapStateToProps)(FAQ);