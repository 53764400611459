import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Index from "./pages/Home";
import './assets/css/style.css';

function App() {
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={"/"}
                    component={Index}
                />
            </Switch>
        </Router>
    );
}

export default App;
